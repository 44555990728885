import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./CreateCard.module.css";
import "../../styles/global/custom-quill-theme.css";

function CreateCard({ addCard, currentCard, setCurrentCard }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (currentCard) {
      setQuestion(currentCard.question);
      setAnswer(currentCard.answer);
    } else {
      setQuestion("");
      setAnswer("");
    }
  }, [currentCard]);

  const handleSubmit = () => {
    if (question && answer) {
      addCard({ question, answer });
      setCurrentCard(null);
      setQuestion("");
      setAnswer("");
    } else {
      alert("Please fill in both the question and answer.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    } else if (
      e.key === "Tab" &&
      e.target ===
        document.querySelector(`.${styles.createCardField}:last-of-type`)
    ) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      // [{ size: [] }],
      // ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ["image"],
      // ["code-block"],
    ],
  };

  return (
    <div className={styles.createCard}>
      <ReactQuill
        theme="snow"
        className={styles.createCardField}
        value={question}
        onChange={setQuestion}
        placeholder="Enter question..."
        onKeyDown={handleKeyDown}
        modules={modules} // Add this line
      />
      <div className={styles.formDivider} />
      <ReactQuill
        theme="snow"
        className={`${styles.createCardField} ${styles.customQuill}`}
        value={answer}
        onChange={setAnswer}
        placeholder="Enter answer..."
        onKeyDown={handleKeyDown}
        modules={modules} // Add this line
      />
      <div className={styles.addCardButtonSection}>
        <div className={styles.addCardTabIcon} onClick={handleSubmit}>
          TAB
        </div>
        <button
          className={styles.addCardButton}
          type="button"
          onClick={handleSubmit}
        >
          Add Card to Deck
        </button>
      </div>
    </div>
  );
}

export default CreateCard;
