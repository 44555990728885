import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const fetchCardSuggestions = async (existingCards) => {
  const prompt = `Based on the following flashcards, suggest eight more related questions and answers. Return the result as a JSON array of objects, each with 'question' and 'answer' properties:\n\n${existingCards
    .map((card) => `Q: ${card.question}\nA: ${card.answer}`)
    .join("\n")}`;

  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: prompt }],
        temperature: 0.7,
        max_tokens: 500,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const suggestions = JSON.parse(response.data.choices[0].message.content);
    return suggestions.map((suggestion) => ({
      ...suggestion,
      presented: false,
      selected: false,
    }));
  } catch (error) {
    console.error("Error fetching suggestions from OpenAI:", error);
    return [];
  }
};

export const createAIDeck = async (prompt) => {
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: `Create a flashcard deck about ${prompt}. Provide the deck title and 5 question-answer pairs in JSON format. The format should be: { "title": "Deck Title", "cards": [{"question": "question content", "answer content": "A1"}, ...] }`,
          },
        ],
        max_tokens: 500,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const content = response.data.choices[0].message.content;
    const deckData = JSON.parse(content);

    return {
      title: deckData.title,
      cards: deckData.cards.map((card) => ({
        id: uuidv4(),
        question: card.question,
        answer: card.answer,
        guessHistory: [],
      })),
    };
  } catch (error) {
    console.error("Error creating AI deck:", error);
    throw error;
  }
};

export const createAIDeckFromAPI = async (prompt) => {
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: `Create a flashcard deck about ${prompt}. Provide the deck title and 5 question-answer pairs in JSON format. The format should be: { "title": "Deck Title", "cards": [{"question": "Q1", "answer": "A1"}, ...] }`,
          },
        ],
        max_tokens: 500,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const content = response.data.choices[0].message.content;
    const deckData = JSON.parse(content);

    return {
      title: deckData.title,
      cards: deckData.cards.map((card) => ({
        id: uuidv4(),
        question: card.question,
        answer: card.answer,
        guessHistory: [],
      })),
    };
  } catch (error) {
    console.error("Error creating AI deck:", error);
    throw error;
  }
};
