import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for createRoot
import { BrowserRouter as Router } from "react-router-dom"; // Added import for Router
import App from "./App"; // Import your main App component
import "./styles/index.css"; // Import global styles if you have any

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
