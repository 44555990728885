import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./HomePage.module.css";
import CreateDeckModal from "../../components/CreateDeckModal/CreateDeckModal";

function HomePage({
  userProfile,
  signOut,
  deleteDeck,
  addDeck,
  onCreateAIDeck,
}) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateDeckModal, setShowCreateDeckModal] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSearchTerm(""); // Clear the search term on 'esc'
    }
  };
  const openModal = () => setShowCreateDeckModal(true);
  const closeModal = () => setShowCreateDeckModal(false);

  // Add a loading state
  if (!userProfile) {
    return <div>Loading user profile...</div>;
  }

  console.log("User decks:", userProfile.decks);

  const filteredDecks = userProfile.decks.filter(
    (deck) =>
      deck.title && deck.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.homePage}>
      <div className={styles.userInfo}>
        <img
          className={styles.avatar}
          src={userProfile.avatar || require("../../assets/default-avatar.png")}
          alt="User Avatar"
        />
        <button
          onClick={() => {
            signOut();
            navigate("/login");
          }}
          className={styles.logoutButton}
        >
          Logout
        </button>
      </div>

      <div className={styles.userDecks}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search Decks..."
          className={styles.searchInput}
        />
        {filteredDecks.length > 0 ? (
          <ul>
            {filteredDecks.map((deck, index) => (
              <li key={index} className={styles.homepageDeck}>
                <Link
                  to={`/deck/${encodeURIComponent(deck.title)}`}
                  className={styles.deckLink}
                  onClick={() => selectDeck(deck)}
                >
                  <h3 className={styles.deckTitle}>{deck.title}</h3>
                  <div className={styles.cardCount}>
                    <svg
                      width="15"
                      height="18"
                      viewBox="0 0 15 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.cardIcon}
                    >
                      <rect
                        x="0.637695"
                        y="3.9624"
                        width="8.29216"
                        height="12.5481"
                        rx="1.02209"
                        stroke="black"
                      />
                      <path
                        d="M1.80919 3.84984L1.81536 3.80604C1.93264 2.97363 2.70251 2.3939 3.53492 2.51117L9.60186 3.36593C10.4343 3.4832 11.014 4.25307 10.8967 5.08548L9.445 15.3896C9.33378 16.1791 8.60363 16.7289 7.81417 16.6177V16.6177"
                        stroke="black"
                      />
                      <path
                        d="M5.25278 2.59905L5.4998 2.10314C5.87462 1.3507 6.78844 1.04457 7.54088 1.41938L13.1354 4.20618C13.8871 4.58061 14.1935 5.49307 13.8201 6.24528L9.48886 14.9721"
                        stroke="black"
                      />
                    </svg>
                    <p className={styles.deckLabel}>
                      {deck.cards.length} cards
                    </p>
                  </div>
                </Link>
                <div
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteDeck(deck.id);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7V5ZM21 7C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5V7ZM11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11H11ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16H9ZM15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11H15ZM13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16H13ZM14.9056 6.24926C15.0432 6.78411 15.5884 7.1061 16.1233 6.96844C16.6581 6.83078 16.9801 6.28559 16.8424 5.75074L14.9056 6.24926ZM4.00221 6.06652L4.87775 19.1996L6.87332 19.0665L5.99779 5.93348L4.00221 6.06652ZM7.87111 22H16.1289V20H7.87111V22ZM19.1222 19.1996L19.9978 6.06652L18.0022 5.93348L17.1267 19.0665L19.1222 19.1996ZM19 5H5V7H19V5ZM3 7H5V5H3V7ZM19 7H21V5H19V7ZM16.1289 22C17.7083 22 19.0172 20.7754 19.1222 19.1996L17.1267 19.0665C17.0917 19.5918 16.6554 20 16.1289 20V22ZM4.87775 19.1996C4.98281 20.7754 6.29171 22 7.87111 22V20C7.34464 20 6.90834 19.5918 6.87332 19.0665L4.87775 19.1996ZM9 11V16H11V11H9ZM13 11V16H15V11H13ZM12 4C13.3965 4 14.5725 4.95512 14.9056 6.24926L16.8424 5.75074C16.2874 3.59442 14.3312 2 12 2V4ZM9.09447 6.24926C9.42756 4.95512 10.6035 4 12 4V2C9.66885 2 7.7126 3.59442 7.1576 5.75074L9.09447 6.24926Z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className={styles.homeDeckaddCTA} onClick={openModal}>
              <div className={styles.deckCardIcon}>
                <div className={styles.deckCardSquare}></div>
                <div className={styles.deckCardSquare}></div>
                <div className={styles.deckCardSquare}></div>
              </div>
              <p>+ Add New Deck</p>
            </li>
          </ul>
        ) : (
          <li className={styles.homeDeckaddCTA} onClick={openModal}>
            <div className={styles.deckCardIcon}>
              <div className={styles.deckCardSquare}></div>
              <div className={styles.deckCardSquare}></div>
              <div className={styles.deckCardSquare}></div>
            </div>
            <p>+ Add New Deck</p>
          </li>
        )}
      </div>
      {showCreateDeckModal && (
        <CreateDeckModal
          onClose={closeModal}
          onCreateDeck={addDeck}
          onCreateAIDeck={onCreateAIDeck}
        />
      )}
    </div>
  );
}

export default HomePage;
