import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from "./DeckLearning.module.css";
import keyInstructions from "../../assets/key-instructions.svg";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DeckSummaryModal from "../../components/DeckSummaryModal/DeckSummaryModal";

ChartJS.register(ArcElement, Tooltip, Legend);

function DeckLearning({ userProfile, updateCardGuesses }) {
  const { deckTitle } = useParams(); // Get the deck title from the URL
  const navigate = useNavigate();
  const [currentDeck, setCurrentDeck] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isDeckCompleted, setIsDeckCompleted] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localGuesses, setLocalGuesses] = useState({});
  const [isStudyingWrongs, setIsStudyingWrongs] = useState(false);
  const [originalDeck, setOriginalDeck] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDeckCompleted(false);
  };

  useEffect(() => {
    if (userProfile && userProfile.decks) {
      const decodedDeckTitle = decodeURIComponent(deckTitle);
      const deck = userProfile.decks.find((d) => d.title === decodedDeckTitle);
      if (deck) {
        setCurrentDeck(deck);
        setOriginalDeck(deck);
      }
    }
  }, [deckTitle, userProfile]);

  const handleKeyDown = useCallback(
    (e) => {
      if (!currentDeck || !originalDeck || currentDeck.cards.length === 0)
        return;

      const currentCard = currentDeck.cards[currentCardIndex];

      switch (e.key) {
        case " ":
          setIsFlipped(!isFlipped);
          break;
        case "ArrowUp":
        case "ArrowDown":
          const isCorrect = e.key === "ArrowUp";
          updateCardGuesses(originalDeck.title, currentCard.id, isCorrect);

          setLocalGuesses((prevGuesses) => ({
            ...prevGuesses,
            [currentCard.id]: isCorrect ? "correct" : "incorrect",
          }));

          if (isCorrect) {
            setCorrectCount((prev) => prev + 1);
          } else {
            setIncorrectCount((prev) => prev + 1);
          }

          // Update the current deck
          setCurrentDeck((prevDeck) => {
            const updatedCards = prevDeck.cards.map((card) =>
              card.id === currentCard.id
                ? {
                    ...card,
                    guessHistory: [
                      ...card.guessHistory,
                      {
                        result: isCorrect ? "correct" : "incorrect",
                        timestamp: Date.now(),
                      },
                    ],
                  }
                : card
            );

            // If studying wrongs, remove correct cards
            const filteredCards =
              isStudyingWrongs && isCorrect
                ? updatedCards.filter((card) => card.id !== currentCard.id)
                : updatedCards;

            return { ...prevDeck, cards: filteredCards };
          });

          if (currentCardIndex === currentDeck.cards.length - 1) {
            setIsDeckCompleted(true);
          } else {
            setCurrentCardIndex((prevIndex) => {
              const nextIndex = prevIndex + 1;
              return nextIndex < currentDeck.cards.length
                ? nextIndex
                : prevIndex;
            });
            setIsFlipped(false);
          }
          break;
        case "ArrowRight":
          setCurrentCardIndex((prevIndex) =>
            prevIndex < currentDeck.cards.length - 1 ? prevIndex + 1 : prevIndex
          );
          setIsFlipped(false);
          break;
        case "ArrowLeft":
          setCurrentCardIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          setIsFlipped(false);
          break;
        default:
          break;
      }
    },
    [
      currentDeck,
      originalDeck,
      currentCardIndex,
      isFlipped,
      updateCardGuesses,
      isStudyingWrongs,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!userProfile || !currentDeck || currentDeck.cards.length === 0) {
    return (
      <div className={styles.deckLearning}>
        <div className={styles.header}>
          <h2>{currentDeck ? currentDeck.title : "Deck"}</h2>
          <Link to="/" className={styles.buttonOutline}>
            Return to Main App
          </Link>
        </div>
        <div className={styles.emptyDeckMessage}>
          {!userProfile
            ? "Loading user profile..."
            : currentDeck
            ? "You've completed all cards in this deck!"
            : "This deck is empty."}
        </div>
      </div>
    );
  }

  const currentCard = currentDeck.cards[currentCardIndex];

  const getCardStatus = (card, index, currentCardIndex) => {
    if (index === currentCardIndex) return "active";
    if (localGuesses[card.id]) return localGuesses[card.id];
    return "unguessed";
  };

  const handleStudyWrongs = () => {
    const incorrectCards = currentDeck.cards.filter((card) => {
      const lastGuess = card.guessHistory[card.guessHistory.length - 1];
      return lastGuess && lastGuess.result === "incorrect";
    });

    if (incorrectCards.length > 0) {
      setCurrentDeck((prevDeck) => ({ ...prevDeck, cards: incorrectCards }));
      setCurrentCardIndex(0);
      setIsFlipped(false);
      setIsDeckCompleted(false);
      setCorrectCount(0);
      setIncorrectCount(0);
      setLocalGuesses({});
      setIsStudyingWrongs(true);
    } else {
      alert("There are no incorrect cards to study. Great job!");
    }
  };

  return (
    <div className={styles.deckLearning}>
      <div className={styles.header}>
        <h2>{currentDeck.title}</h2>
        <Link to="/" className={styles.buttonOutline}>
          Return to Main App
        </Link>
      </div>
      <div className={styles.cardContainer}>
        <button
          className={styles.cardNavigationButton}
          onClick={() =>
            setCurrentCardIndex((prevIndex) => Math.max(prevIndex - 1, 0))
          }
        >
          Left
        </button>

        <div className={`${styles.card} ${isFlipped ? styles.flipped : ""}`}>
          <div className={styles.cardFront}>
            <div dangerouslySetInnerHTML={{ __html: currentCard.question }} />
          </div>
          <div className={styles.cardBack}>
            <div dangerouslySetInnerHTML={{ __html: currentCard.answer }} />
          </div>
          <div className={styles.cardStatusContainer}>
            {currentCard.guessHistory.slice(-5).map((guess, index) => (
              <div
                key={index}
                className={`${styles.statusDot} ${styles[guess.result]}`}
                title={`${guess.result} on ${new Date(
                  guess.timestamp
                ).toLocaleString()}`}
              ></div>
            ))}
          </div>
        </div>
        <button
          className={styles.cardNavigationButton}
          onClick={() =>
            setCurrentCardIndex((prevIndex) =>
              Math.min(prevIndex + 1, currentDeck.cards.length - 1)
            )
          }
        >
          Right
        </button>
      </div>
      <div className={styles.statusIndicator}>
        {currentDeck.cards.map((card, index) => (
          <div
            key={index}
            className={`${styles.statusBar} ${
              styles[getCardStatus(card, index, currentCardIndex)]
            }`}
            style={{ width: `${100 / currentDeck.cards.length}%` }}
          />
        ))}
      </div>
      <div className={styles.cardNavigation}>
        <p>
          {currentCardIndex + 1} / {currentDeck.cards.length}
        </p>
      </div>
      <div className={styles.keyboardInstructions}>
        <img
          src={keyInstructions}
          alt="keyboard instructions"
          className={styles.keyboardInstructionsImage}
        />
      </div>
      {isDeckCompleted && (
        <DeckSummaryModal
          cards={currentDeck.cards}
          onStudyAgain={() => {
            setCurrentDeck(originalDeck);
            setCurrentCardIndex(0);
            setIsFlipped(false);
            setIsDeckCompleted(false);
            setCorrectCount(0);
            setIncorrectCount(0);
            setLocalGuesses({});
            setIsStudyingWrongs(false);
          }}
          onStudyWrongs={handleStudyWrongs}
          onEditDeck={() => {
            navigate(`/?deck=${encodeURIComponent(deckTitle)}`);
          }}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default DeckLearning;
