import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./DeckList.module.css";
import CreateDeckModal from "../CreateDeckModal/CreateDeckModal";

function DeckList({
  decks,
  addDeck,
  selectDeck,
  deleteDeck,
  clearSuggestedCards,
  generateNewSuggestions,
  onCreateAIDeck,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeDeck, setActiveDeck] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSelectDeck = (deckId) => {
    setActiveDeck(deckId);
    selectDeck(deckId);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSearchTerm(""); // Clear the search term on 'esc'
    }
  };

  const filteredDecks = decks.filter(
    (deck) =>
      deck.title && deck.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.deckList}>
      <div className={styles.homeLink}>
        <Link to="/home" className={styles.homeLink}>
          {" "}
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.1567 18.1125V10.02C20.1567 9.44329 19.9077 8.89464 19.4737 8.51486L13.4737 3.26486C12.7197 2.60507 11.5937 2.60507 10.8397 3.26486L4.83973 8.51486C4.4057 8.89464 4.15674 9.44329 4.15674 10.02V18.1125C4.15674 19.2171 5.05217 20.1125 6.15674 20.1125H9.15674C9.70902 20.1125 10.1567 19.6648 10.1567 19.1125V16.1125C10.1567 15.0079 11.0521 14.1125 12.1567 14.1125C13.2613 14.1125 14.1567 15.0079 14.1567 16.1125V19.1125C14.1567 19.6648 14.6044 20.1125 15.1567 20.1125H18.1567C19.2613 20.1125 20.1567 19.2171 20.1567 18.1125Z"
              stroke="#BDBDBD"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
          Home
        </Link>
      </div>
      <div className={styles.deckListHeader}>
        <h2>Decks</h2>
        <button
          className={styles.addDeckButton}
          onClick={() => setShowModal(true)}
        >
          +
        </button>
      </div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Search Decks..."
        className={styles.searchInput}
      />
      <ul>
        {filteredDecks.map((deck) => (
          <li
            key={deck.id}
            onClick={() => handleSelectDeck(deck.id)}
            className={`${styles.deckItem} ${
              activeDeck === deck.id ? styles.active : ""
            }`}
          >
            <div className={styles.deckTitle}>{deck.title}</div>
            <div className={styles.cardCount}>
              <img
                src={require("../../assets/cards-icon.svg").default}
                alt="Cards Icon"
                className={styles.cardIcon}
              />
              Cards: {deck.cards.length}
            </div>
            <div
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                deleteDeck(deck.id);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7V5ZM21 7C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5V7ZM11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11H11ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16H9ZM15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11H15ZM13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16H13ZM14.9056 6.24926C15.0432 6.78411 15.5884 7.1061 16.1233 6.96844C16.6581 6.83078 16.9801 6.28559 16.8424 5.75074L14.9056 6.24926ZM4.00221 6.06652L4.87775 19.1996L6.87332 19.0665L5.99779 5.93348L4.00221 6.06652ZM7.87111 22H16.1289V20H7.87111V22ZM19.1222 19.1996L19.9978 6.06652L18.0022 5.93348L17.1267 19.0665L19.1222 19.1996ZM19 5H5V7H19V5ZM3 7H5V5H3V7ZM19 7H21V5H19V7ZM16.1289 22C17.7083 22 19.0172 20.7754 19.1222 19.1996L17.1267 19.0665C17.0917 19.5918 16.6554 20 16.1289 20V22ZM4.87775 19.1996C4.98281 20.7754 6.29171 22 7.87111 22V20C7.34464 20 6.90834 19.5918 6.87332 19.0665L4.87775 19.1996ZM9 11V16H11V11H9ZM13 11V16H15V11H13ZM12 4C13.3965 4 14.5725 4.95512 14.9056 6.24926L16.8424 5.75074C16.2874 3.59442 14.3312 2 12 2V4ZM9.09447 6.24926C9.42756 4.95512 10.6035 4 12 4V2C9.66885 2 7.7126 3.59442 7.1576 5.75074L9.09447 6.24926Z" />
              </svg>
            </div>
          </li>
        ))}
      </ul>

      {showModal && (
        <CreateDeckModal
          onClose={() => setShowModal(false)}
          onCreateDeck={addDeck}
          onCreateAIDeck={onCreateAIDeck}
        />
      )}
    </div>
  );
}

export default DeckList;
