import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import DeckLearning from "./pages/DeckLearning/DeckLearning";
import DeckLoader from "./components/DeckLoader/DeckLoader";
import { fetchCardSuggestions, createAIDeckFromAPI } from "./api/openai";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

function App() {
  const [user, loading] = useAuthState(auth);
  const [userProfile, setUserProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [currentDeck, setCurrentDeck] = useState(null);
  const [suggestedCards, setSuggestedCards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (user) {
        loadUserProfile(user);
      } else {
        navigate("/login");
      }
    }
  }, [user, loading, navigate]);

  const loadUserProfile = async (user) => {
    if (user) {
      setIsProfileLoading(true);
      const db = getFirestore();
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const decksCollectionRef = collection(db, "users", user.uid, "decks");
        const decksSnapshot = await getDocs(decksCollectionRef);
        const decks = [];

        for (const deckDoc of decksSnapshot.docs) {
          const deckData = deckDoc.data();
          decks.push({
            ...deckData,
            cards: deckData.cards || [],
            id: deckDoc.id, // Add this line to ensure each deck has an id
          });
        }

        setUserProfile({
          ...userData,
          decks: decks,
        });
      } else {
        // Create a new user profile if it doesn't exist
        const newProfile = {
          username: user.displayName,
          email: user.email,
          avatar: user.photoURL,
          decks: [],
        };
        await setDoc(userDocRef, newProfile);
        setUserProfile(newProfile);
        console.log("New user profile created:", newProfile);
      }
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    if (userProfile) {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    }
  }, [userProfile]);

  const updateFirebaseUserProfile = async (updatedProfile) => {
    const db = getFirestore();
    try {
      await setDoc(doc(db, "users", user.uid), updatedProfile);
      console.log("Profile updated in Firestore");
    } catch (error) {
      console.error("Error updating profile in Firestore:", error);
    }
  };

  const addDeck = async (title) => {
    const newDeck = {
      id: uuidv4(),
      title: title,
      cards: [],
      aiSuggestions: [],
    };

    setUserProfile((prevProfile) => ({
      ...prevProfile,
      decks: [...prevProfile.decks, newDeck],
    }));

    if (user) {
      const db = getFirestore();
      const deckRef = doc(db, "users", user.uid, "decks", newDeck.id);
      await setDoc(deckRef, newDeck);
      console.log("New deck saved to Firestore:", newDeck.title);
    }
  };

  const selectDeck = (deckId) => {
    if (userProfile && userProfile.decks) {
      const selectedDeck = userProfile.decks.find((deck) => deck.id === deckId);
      if (selectedDeck) {
        setCurrentDeck(selectedDeck);
        console.log("Deck selected:", selectedDeck);
        navigate(`/deck/${encodeURIComponent(selectedDeck.id)}`);
      } else {
        console.log("Deck not found");
      }
    } else {
      console.log("User profile or decks not yet loaded");
    }
  };

  const addCardToDeck = async (deckId, question, answer) => {
    const newCard = {
      id: Date.now().toString(),
      question,
      answer,
      guessHistory: [],
    };

    // Update local state
    setUserProfile((prevProfile) => {
      const updatedDecks = prevProfile.decks.map((deck) => {
        if (deck.id === deckId) {
          console.log("Card added to deck:", deck.title, newCard);
          return {
            ...deck,
            cards: [...deck.cards, newCard],
          };
        }
        return deck;
      });
      return { ...prevProfile, decks: updatedDecks };
    });

    // Update Firestore
    try {
      const db = getFirestore();
      const deckRef = doc(db, "users", user.uid, "decks", deckId);

      // Check if the document exists
      const docSnap = await getDoc(deckRef);
      if (docSnap.exists()) {
        await updateDoc(deckRef, {
          cards: arrayUnion(newCard),
        });
        console.log("Card saved to Firestore:", newCard);
      } else {
        console.error("Deck not found in Firestore");
        // Revert local state change
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          decks: prevProfile.decks.map((deck) =>
            deck.id === deckId
              ? {
                  ...deck,
                  cards: deck.cards.filter((card) => card.id !== newCard.id),
                }
              : deck
          ),
        }));
      }
    } catch (error) {
      console.error("Error saving card to Firestore:", error);
      // Revert local state change
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        decks: prevProfile.decks.map((deck) =>
          deck.id === deckId
            ? {
                ...deck,
                cards: deck.cards.filter((card) => card.id !== newCard.id),
              }
            : deck
        ),
      }));
    }
  };

  const updateDeckTitle = (oldTitle, newTitle) => {
    setUserProfile((prevProfile) => {
      const updatedDecks = prevProfile.decks.map((deck) => {
        if (deck.title === oldTitle) {
          return { ...deck, title: newTitle };
        }
        return deck;
      });
      return { ...prevProfile, decks: updatedDecks };
    });
  };

  const deleteCard = async (card) => {
    if (!currentDeck) {
      console.error("No active deck to delete the card from.");
      return;
    }

    const db = getFirestore();
    const deckRef = doc(db, "users", user.uid, "decks", currentDeck.id);

    try {
      // Log the current deck and the card to be deleted
      console.log("Current Deck:", currentDeck);
      console.log("Attempting to delete card:", card);

      // Check if the card exists in the current deck
      const cardExists = currentDeck.cards.some((c) => c.id === card.id);
      if (!cardExists) {
        console.error("Card not found in the current deck.");
        return;
      }

      // Delete the card from Firestore
      await updateDoc(deckRef, {
        cards: arrayRemove(card),
      });
      console.log("Card deleted successfully:", card);

      // Update local state to reflect the deletion
      setCurrentDeck((prevDeck) => {
        const updatedCards = prevDeck.cards.filter((c) => c.id !== card.id);
        return { ...prevDeck, cards: updatedCards };
      });

      // Update userProfile to reflect the change in the deck
      setUserProfile((prevProfile) => {
        const updatedDecks = prevProfile.decks.map((deck) => {
          if (deck.id === currentDeck.id) {
            return {
              ...deck,
              cards: deck.cards.filter((c) => c.id !== card.id),
            };
          }
          return deck;
        });
        return { ...prevProfile, decks: updatedDecks };
      });
    } catch (error) {
      console.error("Error deleting card from Firestore:", error);
    }
  };

  const deleteDeck = async (deckId) => {
    if (!user) return;

    try {
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        decks: prevProfile.decks.filter((deck) => deck.id !== deckId),
      }));

      const db = getFirestore();
      const deckRef = doc(db, "users", user.uid, "decks", deckId);
      await deleteDoc(deckRef);

      console.log(`Deck with ID "${deckId}" deleted successfully`);
    } catch (error) {
      console.error("Error deleting deck:", error);
    }
  };

  const updateCardGuesses = (deckTitle, cardId, isCorrect) => {
    const newGuess = {
      result: isCorrect ? "correct" : "incorrect",
      timestamp: new Date().toISOString(),
    };

    setUserProfile((prevProfile) => {
      const updatedDecks = prevProfile.decks.map((deck) => {
        if (deck.title === deckTitle) {
          const updatedCards = deck.cards.map((card) => {
            if (card.id === cardId) {
              return {
                ...card,
                guessHistory: [...card.guessHistory, newGuess],
              };
            }
            return card;
          });
          return { ...deck, cards: updatedCards };
        }
        return deck;
      });
      return { ...prevProfile, decks: updatedDecks };
    });
  };

  const createAIDeck = async (prompt) => {
    try {
      const newDeck = await createAIDeckFromAPI(prompt);
      const deckId = uuidv4();
      const deckWithSuggestions = {
        ...newDeck,
        id: deckId,
        aiSuggestions: [],
      };

      setUserProfile((prevProfile) => ({
        ...prevProfile,
        decks: [...prevProfile.decks, deckWithSuggestions],
      }));

      if (user) {
        const db = getFirestore();
        const deckRef = doc(db, "users", user.uid, "decks", deckId);
        await setDoc(deckRef, deckWithSuggestions);
        console.log("AI deck saved to Firestore:", deckWithSuggestions.title);
      }

      setCurrentDeck(deckWithSuggestions);
      return deckWithSuggestions;
    } catch (error) {
      console.error("Error creating AI deck:", error);
      throw error;
    }
  };

  const updateAiSuggestions = (deckId, newSuggestions) => {
    console.log("Updating AI suggestions for deck:", deckId, newSuggestions);
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      decks: prevProfile.decks.map((deck) =>
        deck.id === deckId ? { ...deck, aiSuggestions: newSuggestions } : deck
      ),
    }));
  };

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Signed in:", result.user);
      navigate("/home");
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const signOut = () => {
    auth.signOut().then(() => {
      setUserProfile(null);
      navigate("/login");
    });
  };

  const goToDeckLearning = (deckTitle) => {
    navigate(`/learn/${encodeURIComponent(deckTitle)}`);
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          loading ? (
            <div>Loading...</div>
          ) : user ? (
            <Navigate to="/home" replace />
          ) : (
            <Login signIn={signIn} />
          )
        }
      />
      <Route
        path="/home"
        element={
          loading ? (
            <div>Loading...</div>
          ) : user ? (
            <HomePage
              userProfile={userProfile}
              signOut={signOut}
              deleteDeck={deleteDeck}
              addDeck={addDeck}
              onCreateAIDeck={createAIDeck}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/learn/:deckTitle"
        element={
          loading ? (
            <div>Loading...</div>
          ) : user ? (
            <DeckLearning
              userProfile={userProfile}
              updateCardGuesses={updateCardGuesses}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/deck/:deckTitle"
        element={
          loading ? (
            <div>Loading...</div>
          ) : user ? (
            <DeckLoader
              userProfile={userProfile}
              currentDeck={currentDeck}
              setCurrentDeck={setCurrentDeck}
              addCardToDeck={addCardToDeck}
              updateDeckTitle={updateDeckTitle}
              deleteCard={deleteCard}
              updateAiSuggestions={updateAiSuggestions}
              addDeck={addDeck}
              selectDeck={selectDeck}
              deleteDeck={deleteDeck}
              onCreateAIDeck={createAIDeck}
            />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/"
        element={<Navigate to={user ? "/home" : "/login"} replace />}
      />
    </Routes>
  );
}

export default App;
