import React, { useState } from "react";
import styles from "./CreateDeckModal.module.css";

function CreateDeckModal({ onClose, onCreateDeck, onCreateAIDeck }) {
  const [deckType, setDeckType] = useState("manual");
  const [deckTitle, setDeckTitle] = useState("");
  const [aiPrompt, setAiPrompt] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (deckType === "manual") {
      onCreateDeck(deckTitle); // This should call the updated addDeck function
    } else {
      onCreateAIDeck(aiPrompt);
    }
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <h2>Create New Deck</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <div className={styles.createDeckLabels}>
              <label>
                <input
                  type="radio"
                  value="manual"
                  checked={deckType === "manual"}
                  onChange={() => setDeckType("manual")}
                />
                Create from scratch
              </label>
              <label>
                <input
                  type="radio"
                  value="ai"
                  checked={deckType === "ai"}
                  onChange={() => setDeckType("ai")}
                />
                Create with AI
              </label>
            </div>
          </div>
          {deckType === "manual" ? (
            <input
              type="text"
              value={deckTitle}
              onChange={(e) => setDeckTitle(e.target.value)}
              placeholder="Enter deck title"
              required
            />
          ) : (
            <textarea
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
              placeholder="Enter prompt for AI-generated deck"
              required
            />
          )}
          <div className={styles.buttonGroup}>
            <button type="submit">Create Deck</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateDeckModal;
