import React from "react";
import styles from "./card.module.css";

function Card({ card, index, onDelete, onClick, className }) {
  const cardClassName = `${styles.card} ${className || ""}`.trim();

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the card's onClick
    onDelete(); // Call the delete function
  };

  return (
    <div className={cardClassName} onClick={onClick}>
      {index && <p className={styles.cardLabel}>{index}. </p>}
      <div
        className={styles.question}
        dangerouslySetInnerHTML={{ __html: card.question }}
      />
      <div
        className={styles.answer}
        dangerouslySetInnerHTML={{ __html: card.answer }}
      />
      <div className={styles.deleteIcon} onClick={handleDeleteClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7V5ZM21 7C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5V7ZM11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11H11ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16H9ZM15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11H15ZM13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16H13ZM14.9056 6.24926C15.0432 6.78411 15.5884 7.1061 16.1233 6.96844C16.6581 6.83078 16.9801 6.28559 16.8424 5.75074L14.9056 6.24926ZM4.00221 6.06652L4.87775 19.1996L6.87332 19.0665L5.99779 5.93348L4.00221 6.06652ZM7.87111 22H16.1289V20H7.87111V22ZM19.1222 19.1996L19.9978 6.06652L18.0022 5.93348L17.1267 19.0665L19.1222 19.1996ZM19 5H5V7H19V5ZM3 7H5V5H3V7ZM19 7H21V5H19V7ZM16.1289 22C17.7083 22 19.0172 20.7754 19.1222 19.1996L17.1267 19.0665C17.0917 19.5918 16.6554 20 16.1289 20V22ZM4.87775 19.1996C4.98281 20.7754 6.29171 22 7.87111 22V20C7.34464 20 6.90834 19.5918 6.87332 19.0665L4.87775 19.1996ZM9 11V16H11V11H9ZM13 11V16H15V11H13ZM12 4C13.3965 4 14.5725 4.95512 14.9056 6.24926L16.8424 5.75074C16.2874 3.59442 14.3312 2 12 2V4ZM9.09447 6.24926C9.42756 4.95512 10.6035 4 12 4V2C9.66885 2 7.7126 3.59442 7.1576 5.75074L9.09447 6.24926Z" />
        </svg>
      </div>
    </div>
  );
}

export default Card;
