import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Deck from "../Deck/Deck";
import DeckList from "../DeckList/DeckList"; // Import DeckList
import styles from "./DeckLoader.module.css"; // You may need to create this CSS module

function DeckLoader({
  userProfile,
  currentDeck,
  setCurrentDeck,
  addCardToDeck,
  updateDeckTitle,
  deleteCard,
  updateAiSuggestions,
  addDeck,
  selectDeck,
  deleteDeck,
  onCreateAIDeck,
}) {
  const { deckTitle } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userProfile && userProfile.decks) {
      const decodedDeckTitle = decodeURIComponent(deckTitle);
      const foundDeck = userProfile.decks.find(
        (deck) => deck.title === decodedDeckTitle
      );
      if (foundDeck) {
        setCurrentDeck(foundDeck);
      }
      setIsLoading(false);
    }
  }, [userProfile, deckTitle, setCurrentDeck]);

  if (isLoading) {
    return <div>Loading deck...</div>;
  }

  return (
    <div className={styles.deckLoaderContainer}>
      <DeckList
        decks={userProfile.decks}
        addDeck={addDeck}
        selectDeck={selectDeck}
        deleteDeck={deleteDeck}
        onCreateAIDeck={onCreateAIDeck}
      />
      <div className={styles.deckContent}>
        {currentDeck ? (
          <Deck
            deck={currentDeck}
            addCardToDeck={addCardToDeck}
            updateDeckTitle={updateDeckTitle}
            deleteCard={deleteCard}
            userProfile={userProfile}
            updateAiSuggestions={updateAiSuggestions}
          />
        ) : (
          <div>Deck not found.</div>
        )}
      </div>
    </div>
  );
}

export default DeckLoader;
