import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate
import Card from "../Card/Card";
import CreateCard from "../CreateCard/CreateCard";
import styles from "./Deck.module.css";
import cardStyles from "../Card/card.module.css";
import { fetchCardSuggestions } from "../../api/openai";
import { v4 as uuidv4 } from "uuid";

function Deck({
  deck,
  addCardToDeck,
  updateDeckTitle,
  deleteCard,
  userProfile,
  updateAiSuggestions,
  suggestedCards,
}) {
  const navigate = useNavigate(); // Create navigate function

  // Add a loading state
  const [isLoading, setIsLoading] = useState(!deck);

  // State declarations
  const [cards, setCards] = useState(deck?.cards || []);
  const [title, setTitle] = useState(deck?.title || "");
  const [isEditing, setIsEditing] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [allSuggestions, setAllSuggestions] = useState(
    deck.aiSuggestions || []
  );
  const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hiddenCards, setHiddenCards] = useState(new Set());

  // Effect to update component state when deck prop changes
  useEffect(() => {
    if (deck) {
      setCards(deck.cards);
      setTitle(deck.title);
      setAllSuggestions(deck.aiSuggestions || []);
      updateDisplayedSuggestions();
      setIsLoading(false);
    }
  }, [deck]);

  if (isLoading) {
    return <div>Loading deck...</div>;
  }

  if (!deck) {
    return <div>Deck not found.</div>;
  }

  const updateDisplayedSuggestions = useCallback(() => {
    const unpresentedSuggestions = allSuggestions.filter(
      (s) => !s.presented && !s.selected
    );
    setDisplayedSuggestions(unpresentedSuggestions.slice(0, 5));
  }, [allSuggestions]);

  useEffect(() => {
    updateDisplayedSuggestions();
  }, [updateDisplayedSuggestions]);

  // Function to fetch AI-generated card suggestions
  const fetchSuggestions = async () => {
    setLoading(true);
    try {
      const suggestions = await fetchCardSuggestions(deck.cards);
      const newSuggestions = suggestions.map((suggestion) => ({
        ...suggestion,
        id: uuidv4(),
        presented: false,
        selected: false,
      }));
      setAllSuggestions(newSuggestions);
      updateAiSuggestions(deck.title, newSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handler for adding a new card to the deck
  const handleAddCard = useCallback(
    ({ question, answer, id }) => {
      const newCard = { id: id || Date.now(), question, answer };
      setCards((prevCards) => [...prevCards, newCard]);
      addCardToDeck(deck.id, question, answer);
    },
    [addCardToDeck, deck.id]
  );

  // Handler for adding a recommended card to the deck
  const handleRecommendationClick = useCallback(
    (card) => {
      handleAddCard(card);
      setHiddenCards((prevHiddenCards) =>
        new Set(prevHiddenCards).add(card.id)
      );
      setAllSuggestions((prevSuggestions) =>
        prevSuggestions.map((c) =>
          c.id === card.id ? { ...c, selected: true } : c
        )
      );
      updateAiSuggestions(
        deck.title,
        allSuggestions.map((c) =>
          c.id === card.id ? { ...c, selected: true } : c
        )
      );
    },
    [handleAddCard, deck.title, allSuggestions, updateAiSuggestions]
  );

  // Handlers for editing the deck title
  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    updateDeckTitle(deck.title, title);
    setIsEditing(false);
  };

  // Handler for deleting a card from the deck
  const handleDeleteCard = (card) => {
    console.log("Deleting card:", card.question, card.answer);
    deleteCard(card); // Pass the card object directly
    setCards((prevCards) => prevCards.filter((c) => c.id !== card.id));
  };

  // Handler for selecting a card (sets it as the current card)
  const handleCardClick = (card) => {
    setCurrentCard(card);
  };

  // Handler for initiating the deck learning process
  const handleLearnDeck = () => {
    navigate(`/learn/${encodeURIComponent(deck.title)}`); // Use navigate for redirection
  };

  // Handler for deleting a suggestion and potentially fetching new ones
  const handleDeleteSuggestion = useCallback(
    (card) => {
      setAllSuggestions((prevSuggestions) =>
        prevSuggestions.map((c) =>
          c.id === card.id ? { ...c, presented: true, selected: true } : c
        )
      );
      updateAiSuggestions(
        deck.title,
        allSuggestions.map((c) =>
          c.id === card.id ? { ...c, presented: true, selected: true } : c
        )
      );
    },
    [deck.title, allSuggestions, updateAiSuggestions]
  );

  // New effect to fetch new suggestions if needed
  useEffect(() => {
    const fetchNewSuggestionsIfNeeded = async () => {
      const displayableSuggestions = allSuggestions.filter(
        (s) => !s.presented && !s.selected
      );
      if (displayableSuggestions.length < 5) {
        await fetchSuggestions();
      }
      updateDisplayedSuggestions();
    };

    fetchNewSuggestionsIfNeeded();
  }, [deck, allSuggestions]);

  // Component render
  return (
    <div className={styles.mainDeckWindow}>
      {/* Deck title and learn button */}
      <div className={styles.deckInfo}>
        <Link to="/home" className={styles.homeLink}>
          {" "}
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.1567 18.1125V10.02C20.1567 9.44329 19.9077 8.89464 19.4737 8.51486L13.4737 3.26486C12.7197 2.60507 11.5937 2.60507 10.8397 3.26486L4.83973 8.51486C4.4057 8.89464 4.15674 9.44329 4.15674 10.02V18.1125C4.15674 19.2171 5.05217 20.1125 6.15674 20.1125H9.15674C9.70902 20.1125 10.1567 19.6648 10.1567 19.1125V16.1125C10.1567 15.0079 11.0521 14.1125 12.1567 14.1125C13.2613 14.1125 14.1567 15.0079 14.1567 16.1125V19.1125C14.1567 19.6648 14.6044 20.1125 15.1567 20.1125H18.1567C19.2613 20.1125 20.1567 19.2171 20.1567 18.1125Z"
              stroke="#BDBDBD"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
          Home
        </Link>
        {isEditing ? (
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            autoFocus
            className={styles.titleInput}
          />
        ) : (
          <div className={styles.titleContainer}>
            <h2 onClick={handleTitleClick}>{title}</h2>
          </div>
        )}
        <button
          className={styles.deckLearnButton}
          onClick={handleLearnDeck} // Ensure this calls the correct function
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0118 4V8H19H15.0118M5 20V16H9M4 12C4 7.58172 7.58172 4 12 4C14.6362 4 17.0303 5.27512 18.5 7.24224M20 12C20 16.4183 16.4183 20 12 20C9.36378 20 6.96969 18.7249 5.5 16.7578M12 12H12.01"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Learn Deck
        </button>
      </div>

      {/* Main deck container */}
      <div className={styles.deckContainer}>
        {/* Assembled deck section */}
        <div className={styles.assembledDeck}>
          <div className={styles.assembledDeckHeader}>
            <h3>Assembled Deck</h3>
            <p className={styles.deckCardCount}>{cards.length} cards</p>
          </div>
          {cards.map((card, index) => (
            <Card
              key={card.id}
              card={card}
              index={index + 1}
              onDelete={() => handleDeleteCard(card)}
              onClick={() => handleCardClick(card)}
              id={card.id}
            />
          ))}
        </div>

        {/* Create new card component */}
        <CreateCard
          addCard={handleAddCard}
          currentCard={currentCard}
          setCurrentCard={setCurrentCard}
        />

        {/* Card recommendations section */}
        <div className={styles.cardRecommendations}>
          <h3>Card Recommendations</h3>
          {loading ? (
            <div className={styles.loadingSpinner}>Loading...</div>
          ) : displayedSuggestions.length > 0 ? (
            displayedSuggestions.map((card) => (
              <Card
                key={card.id}
                card={card}
                onClick={() => handleRecommendationClick(card)}
                onDelete={() => handleDeleteSuggestion(card)}
                id={card.id}
                className={`${cardStyles.recommendedCard} ${
                  hiddenCards.has(card.id) ? styles.hidden : ""
                }`}
              />
            ))
          ) : (
            <p>No recommendations available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Deck;
