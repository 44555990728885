import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import logo from "../../assets/flutterdeck-logo.svg";

function Login({ signIn }) {
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      await signIn();
      navigate("/home");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <img src={logo} alt="FlutterDeck" className={styles.logo} />
      <div className={styles.loginContentContainer}>
        <p>Login to begin building your first ai-powered flutterdeck</p>
        <button onClick={handleSignIn} className="buttonPrimaryDk">
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default Login;
