import React from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "./DeckSummaryModal.module.css";

function DeckSummaryModal({
  cards,
  onStudyAgain,
  onEditDeck,
  onStudyWrongs,
  onClose,
}) {
  const { correctCount, incorrectCount } = cards.reduce(
    (counts, card) => {
      const lastGuess = card.guessHistory[card.guessHistory.length - 1];
      if (lastGuess) {
        if (lastGuess.result === "correct") {
          counts.correctCount++;
        } else {
          counts.incorrectCount++;
        }
      }
      return counts;
    },
    { correctCount: 0, incorrectCount: 0 }
  );

  const data = {
    labels: ["Correct", "Incorrect"],
    datasets: [
      {
        data: [correctCount, incorrectCount],
        backgroundColor: ["#000000", "#DDDDCE"],
        hoverBackgroundColor: ["#222222", "#ECECE2"],
      },
    ],
  };

  const options = {
    cutout: "70%",
  };

  return (
    <>
      <div className={styles.modalOverlay} onClick={onClose}></div>
      <div className={styles.modal}>
        <h3 className={styles.title}>Deck Summary</h3>
        <div className={styles.pieChart}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={styles.buttonGroup}>
          <button className={styles.button} onClick={onStudyWrongs}>
            Study Wrongs
          </button>
          <button className={styles.button} onClick={onStudyAgain}>
            Study Again
          </button>
          <button className={styles.button} onClick={onEditDeck}>
            Edit Deck
          </button>
        </div>
      </div>
    </>
  );
}

export default DeckSummaryModal;
